import {mapActions, mapGetters, mapMutations} from "vuex";
import characteristics from "@/modules/product-card/pages/characteristics/index.vue";
import archedCanopy from '@/modules/product-card/pages/roof-calculation/pages/arched_canopy/index.vue'
import gableCanopy from '@/modules/product-card/pages/roof-calculation/pages/gable_canopy/index.vue'
import singlePitchedCanopy from '@/modules/product-card/pages/roof-calculation/pages/single_pitched_canopy/index.vue'
//sections
export default {
    name: "calculator",
    components: {
        characteristics,
        archedCanopy,
        gableCanopy,
        singlePitchedCanopy
    },
    data() {
        return {
            checkedRoof:'arched_canopy'
        }
    },
    watch: {

    },
    mounted() {
        this.getCalculators()
    },
    created() {

    },
    computed: {
        ...mapGetters({
            calculators: 'constructors/calculators',
            specifications: 'constructors/calculatorSpecifications'
        })
    },

    methods: {
        ...mapMutations({}),
        ...mapActions({
            getCalculators: 'constructors/CET_CALCULATORS',
            getSpecifications: 'constructors/CET_ARCH_SPECIFICATION'
        }),
        checkRoof(item){
            this.checkedRoof = item
        }
    }
}
